import { AuthLayout } from '../views/auth/auth-layout';
import { LoginForm } from '../views/auth/login';

export const LoginRoute = () => {
  return (
    <AuthLayout>
      <LoginForm />
    </AuthLayout>
  );
};
