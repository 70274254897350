import type { FC } from 'react';

import clsx from 'clsx';

import type { SubmissionStatus as SubmissionStatusType } from '../../../types';

interface SubmissionStatusProps {
  status: SubmissionStatusType;
}

const getStyleForStatus = (status: SubmissionStatusType) =>
  ({
    ['Failed']: 'text-red-500',
    ['Processed']: 'text-green-500',
    ['Processing']: 'text-yellow-500',
    ['Uploading']: 'text-blue-500',
  }[status]);

export const SubmissionStatus: FC<SubmissionStatusProps> = ({ status }) => {
  return <p className={clsx('text-gray-500 font-semibold ml-auto text-sm', getStyleForStatus(status))}>{status}</p>;
};
