import format from 'date-fns/format';
import _, { isArray, isDate, isNil, omit } from 'lodash';
import { useSearchParams as useRouterSearchParams } from 'react-router-dom';

import type { ApplyFiltersFn, FiltersRawState } from './types';

const getCurrentFilters = (params: URLSearchParams): FiltersRawState => ({
  archived: params.get('archived') ?? undefined,
  createdAtDateStart: params.get('createdAtDateStart') ?? undefined,
  createdAtDateEnd: params.get('createdAtDateEnd') ?? undefined,
  status: params.get('status') ?? undefined,
  userId: params.get('userId') ?? undefined,
});

export const useFilterSearchParams = () => {
  const [params, setParams] = useRouterSearchParams({});

  const applyFilters: ApplyFiltersFn = (key, value) => {
    const currentFilters = getCurrentFilters(params);
    const parsedFilters = _(currentFilters).omitBy(_.isNil).value();

    if (isNil(value)) {
      return setParams(omit(parsedFilters, key as string));
    }

    if (isArray(value)) {
      return setParams({ ...parsedFilters, [key]: value.join(',') });
    }

    if (isDate(value)) {
      return setParams({ ...parsedFilters, [key]: format(value, 'yyyy-MM-dd') });
    }

    return setParams({ ...parsedFilters, [key]: value });
  };

  const clearFilters = () => {
    // @ts-ignore
    setParams({}, { replace: true });
  };

  const { archived, createdAtDateStart, createdAtDateEnd, status, userId } = getCurrentFilters(params);
  const parsedFilters = {
    archived: archived ? Number(archived) : undefined,
    createdAtDateStart: createdAtDateStart ? new Date(createdAtDateStart) : undefined,
    createdAtDateEnd: createdAtDateEnd ? new Date(createdAtDateEnd) : undefined,
    status,
    userId,
  };

  return {
    applyFilters,
    clearFilters,
    filters: parsedFilters,
    params,
  };
};
