import type { FC, ReactElement } from 'react';

interface DetailsCardProps {
  icon: ({ className }: { className: string }) => ReactElement;
  label: string;
  value: string;
}

export const DetailsCard: FC<DetailsCardProps> = ({ icon: IconComponent, label, value }) => {
  return (
    <div className="flex flex-col px-4 py-3 bg-gray-100 rounded-lg">
      <p className="text-sm leading-5 font-medium text-gray-500">{label}</p>
      <div className="flex items-center">
        <IconComponent className="h-5 w-5" />
        <p className="ml-2.5 text-xl leading-7 font-semibold text-gray-900">{value}</p>
      </div>
    </div>
  );
};
