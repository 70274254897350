import type { FC } from 'react';
import React from 'react';

import clsx from 'clsx';
import type { LinkProps } from 'react-router-dom';
import { Link, matchRoutes, useLocation } from 'react-router-dom';

interface NavItemProps extends LinkProps, Omit<React.HTMLProps<HTMLAnchorElement>, 'target' | 'ref'> {
  isExternal?: boolean;
  label: string;
  paths: string[];
}

const renderContent = (isActive: boolean, label: string) => (
  <div
    className={clsx(
      'flex h-full items-center p-5 hover:text-white hover:shadow-md',
      isActive && 'shadow-accent hover:shadow-accent text-white bg-gray-1000'
    )}
  >
    {label}
  </div>
);

export const NavItem: FC<NavItemProps> = ({ isExternal = false, label, paths, ...linkProps }) => {
  const location = useLocation();
  const matches = matchRoutes(
    paths.map((path) => ({ path })),
    location
  );

  const isActive = Boolean(matches && matches.length > 0);

  if (isExternal) {
    return (
      <a className="cursor-pointer" {...linkProps}>
        {renderContent(isActive, label)}
      </a>
    );
  }

  return <Link {...linkProps}>{renderContent(isActive, label)}</Link>;
};
