import { useUniqueIdentifier } from '../../../hooks';

interface UseFieldInfoArgs {
  label?: string;
}

export const useFieldInfo = ({ label }: UseFieldInfoArgs) => {
  const id = useUniqueIdentifier('field');
  const labelId = `${id}-label`;

  const renderProps = {
    id,
    'aria-labelledby': label ? labelId : undefined,
  };

  return {
    id,
    labelId,
    renderProps,
  };
};
