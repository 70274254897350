import type { FC } from 'react';

import { PlusIcon } from '@heroicons/react/outline';

import { Button, H3, useModal } from '../../../../components';
import { useSubmissions } from '../../../../data/use-submissions';
import { NewSubmissionModal } from '../../../new-submission-modal';
import { Filters } from '../filters';
import { useFilterSearchParams } from '../filters';
import { SubmissionsInbox } from '../submissions-inbox';

export const SubmissionsPanel: FC = () => {
  const { applyFilters, clearFilters, filters, params } = useFilterSearchParams();
  const { isOpen, onClose, onOpen } = useModal();
  const { data, isLoading } = useSubmissions(params);

  return (
    <div>
      <NewSubmissionModal isOpen={isOpen} onClose={onClose} />
      <div className="flex justify-between items-center xl:p-5 lg:p-3">
        <H3>Submissions</H3>
        <Button iconBefore={<PlusIcon />} onClick={onOpen}>
          New submission
        </Button>
      </div>
      <Filters applyFilters={applyFilters} onClearFilters={clearFilters} values={filters} />
      <SubmissionsInbox data={data} isLoading={isLoading} />
    </div>
  );
};
