import type { FC, ReactNode } from 'react';

import clsx from 'clsx';

interface BodyCellProps {
  children: ReactNode;
  className?: string;
}

export const BodyCell: FC<BodyCellProps> = ({ children, className }) => {
  return (
    <td
      className={clsx(
        'text-sm leading-5 font-normal border-b border-slate-100 text-gray-600 py-4 px-6 whitespace-nowrap text-center',
        className
      )}
    >
      {children}
    </td>
  );
};
