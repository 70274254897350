import type { Props, StylesConfig } from 'react-select';
import ReactSelect from 'react-select';

const customStyle: StylesConfig<any> = {
  control: (style, state) => ({
    ...style,
    borderColor: state.hasValue ? '#4B5563' : '#D1D5DB', // gray-600 or gray-300
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)', // shadow-sm
    borderRadius: '6px', // rounded-md
  }),
  clearIndicator: (style) => ({
    ...style,
    marginRight: '-16px',
    color: '#4B5563', // gray-600
  }),
  dropdownIndicator: (style) => ({
    ...style,
    color: '#6B7280', // gray-500
  }),
  indicatorSeparator: (style) => ({
    ...style,
    width: 0,
  }),
  input: (style) => ({
    ...style,
    color: '#4B5563', // gray-600
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  }),
  placeholder: (style) => ({
    ...style,
    color: '#9CA3AF', // gray-400
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  }),
  singleValue: (style) => ({
    ...style,
    color: '#4B5563', // gray-600
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  }),
};

export function Select<Option, IsMulti extends boolean = false>(props: Props<Option, IsMulti>) {
  return <ReactSelect isClearable placeholder="Select" styles={customStyle} {...props} />;
}
