import type { PasswordRuleValidator } from '../types';

export const minLengthValidator: PasswordRuleValidator = ({ newPassword }) =>
  Boolean(newPassword && newPassword.length >= 10);

export const lowerAndUpperCaseValidator: PasswordRuleValidator = ({ newPassword }) =>
  Boolean(newPassword && /[A-Z]/g.test(newPassword) && /[a-z]/g.test(newPassword));

export const atLeastOneNumberValidator: PasswordRuleValidator = ({ newPassword }) =>
  Boolean(newPassword && /[0-9]/g.test(newPassword));

export const specialCharactersValidator: PasswordRuleValidator = ({ newPassword }) =>
  Boolean(newPassword && /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g.test(newPassword));

export const confirmationPasswordValidator: PasswordRuleValidator = ({ newPassword, confirmNewPassword }) =>
  Boolean(confirmNewPassword && newPassword && newPassword === confirmNewPassword);
