import type { ReactNode } from 'react';

export enum TableCellAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export interface TableColumn<Data> {
  alignment?: TableCellAlignment;
  id: keyof Data;
  label: string;
}

export interface TableCell<Data> {
  id: keyof Data;
  render?: (value: unknown) => ReactNode;
  value: string | number;
}

export interface SortOptions<Data> {
  sortBy: keyof Data;
  sortDirection: SortDirection;
}
