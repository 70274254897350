import { useState } from 'react';

import { downloadFileFromUrl } from '../helpers';
import { getFetcher } from './use-query';

export const useGetAttachmentFile = (submissionId: string, fileName: string) => {
  const [isLoading, setIsLoading] = useState(false);

  const execute = async () => {
    try {
      setIsLoading(true);
      const data = await getFetcher(`${process.env.REACT_APP_API_URL}/submissions/${submissionId}/download/attachment`);
      downloadFileFromUrl(data.data.url, fileName);
      return data.data.url;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    execute,
    isLoading,
  };
};
