export enum SubmissionStatus {
  Failed = 'Failed',
  Processed = 'Processed',
  Processing = 'Processing',
  Uploading = 'Uploading',
}

export type DigiProp1FailedReason =
  | 'Could not find all of the required columns within the schedule file'
  | 'The processing failed due to an unknown issue'
  | 'The processing timed out while trying to process the schedule file';

export interface Submission {
  processedFile?: string; // filled by validation
  goldenRulePassRate?: number; // filled by validation
  avgConfidenceRating?: number; // filled by validation
  rows?: number; // filled by validation
  processingTime?: number; // seconds - filled by validation

  failedReason?: DigiProp1FailedReason; // filled by validation

  archived?: 1 | 0;
  createdAt: string; // ISO
  createdAtDate: string; // Y-m-d
  file: string;
  fileId: string; // uuid directory
  fileSize: string;
  from: string; // user email
  gsi?: 0;
  id: string;
  responseSent?: string;
  starred?: 1 | 0;
  status: SubmissionStatus;
  subject?: string;
  submissionSourceId?: string; // tenant id
  userEmail?: string;
  userFirstName?: string;
  userId?: string;
  userLastName?: string;
  via: 'email' | 'web';
}

export interface User {
  firstName: string;
  id: string;
  lastName: string;
}

export interface UserStats {
  avgConfidenceRating: number;
  avgProcessingTime: number;
  failed: number;
  goldenRulePassRate: number;
  processed: number;
  totalRows: number;
  userActive: boolean;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
}

export interface DashboardStatistics {
  stats: {
    avgConfidenceRating: number;
    avgProcessingTime: number;
    goldenRulePassRateFailed: number;
    goldenRulePassRatePassed: number;
    rows: number;
    submissionFailed: number;
    submissionMethodEmail: number;
    submissionMethodWeb: number;
    submissionProcessed: number;
    submissions: number;
  };
  userStats: UserStats[];
}
