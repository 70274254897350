import type { FC } from 'react';

import { LogoutIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import { capitalizeFirstLetter } from '../../../helpers/capitalize-first-letter';

interface UserDetailsPoppoverProps {
  email: string;
  firstName: string;
  lastName: string;
  onLogout: () => void;
  userType: string;
}

const getPillClassName = (userType: string) => {
  if (userType === 'admin') {
    return 'bg-blue-100 text-blue-600';
  }

  return 'bg-teal-100 text-teal-600';
};

export const UserDetailsPoppover: FC<UserDetailsPoppoverProps> = ({
  email,
  firstName,
  lastName,
  onLogout,
  userType,
}) => {
  const pillClassName = getPillClassName(userType);

  return (
    <div className="ring-1 ring-black ring-opacity-5 shadow-lg rounded-b-lg bg-white" style={{ minWidth: '224px' }}>
      <div className="flex flex-col border-b border-gray-200 px-4 py-3 border-b border-gray-200">
        <p className={clsx('self-baseline py-0.5 px-3 rounded-xl mb-5', pillClassName)}>
          {capitalizeFirstLetter(userType)}
        </p>
        <p className="text-sm leading-5 font-normal text-gray-700">
          {firstName} {lastName}
        </p>
        <p className="text-sm leading-5 font-medium text-gray-400">{email}</p>
      </div>
      <div className="flex p-3 text-gray-900 hover:bg-gray-100" onClick={onLogout}>
        <LogoutIcon className="w-5 h-5 mr-3" />
        <p className="text-sm leading-5 font-normal">Log out</p>
      </div>
    </div>
  );
};
