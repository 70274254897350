import type { SubmissionParams } from './app-paths';
import { appPaths } from './app-paths';
import { SubmissionParamsKeys } from './constants';

export const routePaths = {
  singleSubmission: (params: SubmissionParams) =>
    `${appPaths.home()}${appPaths
      .singleSubmission()
      .replace(`:${SubmissionParamsKeys.SUBMISSION_ID}`, params.submissionId)}`,
};
