export const EmptyResults = () => {
  return (
    <div className="flex flex-col justify-center pt-7">
      <p className="text-base leading-6 font-semibold text-gray-900 text-center mb-2">No results</p>
      <p className="text-sm leading-5 font-normal text-gray-700 text-center">
        There are no submissions <br /> that match your filters.
      </p>
    </div>
  );
};
