import { PhotographIcon } from '@heroicons/react/outline';

export const DropzoneEmpty = () => {
  return (
    <div className="flex flex-col w-full items-center justify-center">
      <PhotographIcon className="w-9 h-9 text-gray-400 mb-3" strokeWidth={1.5} />
      <span className="text-sm leading-5 font-medium text-gray-600 pb-1">
        <span className="text-blue-500 cursor-pointer">Upload a file</span> or drag and drop
      </span>
      <p className="text-xs leading-4 font-normal text-gray-500">xls or xlsx only, up to 100Mb</p>
    </div>
  );
};
