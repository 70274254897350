import type { FC } from 'react';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { appPaths } from '../helpers';
import { DashboardRoute } from './DashboardRoute';
import { EmptySubmissionRoute } from './EmptySubmissionRoute';
import { ForgotPasswordRoute } from './ForgotPasswordRoute';
import { LoginRoute } from './LoginRoute';
import { RequireAuthentication } from './RequireAuthentication';
import { ResetPasswordRoute } from './ResetPasswordRoute';
import { SetupPasswordRoute } from './SetupPasswordRoute';
import { SingleSubmissionRoute } from './SingleSubmissionRoute';
import { SubmissionsRoute } from './SubmissionsRoute';

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={appPaths.login()} element={<LoginRoute />} />
        <Route path={appPaths.forgotPassword()} element={<ForgotPasswordRoute />} />
        <Route path={appPaths.setupPassword()} element={<SetupPasswordRoute />} />
        <Route path={appPaths.resetPassword()} element={<ResetPasswordRoute />} />
        <Route
          path={appPaths.home()}
          element={
            <RequireAuthentication>
              <SubmissionsRoute />
            </RequireAuthentication>
          }
        >
          <Route path={appPaths.singleSubmission()} element={<SingleSubmissionRoute />} />
          <Route index element={<EmptySubmissionRoute />} />
        </Route>
        <Route
          path={appPaths.dashboard()}
          element={
            <RequireAuthentication>
              <DashboardRoute />
            </RequireAuthentication>
          }
        />
        <Route path="*" element={<Navigate replace to={appPaths.home()} />} />
      </Routes>
    </BrowserRouter>
  );
};
