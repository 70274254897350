import type { FC } from 'react';
import React from 'react';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import { Input } from '../input';
import { DateIcons } from './components/DateIcons';

interface DateInputProps {
  id?: string;
  isDisabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  onChange?: (date?: Date) => void;
  value?: Date;
}

export const DateInput: FC<DateInputProps> = ({ id, isDisabled, maxDate, minDate, onChange, value }) => {
  const handleChange = (date: Date) => {
    onChange?.(date);
  };

  const handleClear = () => {
    onChange?.(undefined);
  };

  return (
    <DatePicker
      customInput={
        <Input iconAfter={<DateIcons hasValue={!!value} onClear={handleClear} />} textClassName="text-gray-600" />
      }
      dateFormat="dd MMMM yyyy"
      disabled={isDisabled}
      formatWeekDay={(nameOfDay) => nameOfDay.charAt(0).toUpperCase()}
      id={id}
      maxDate={maxDate}
      minDate={minDate}
      selected={value}
      onChange={handleChange}
      placeholderText="Select"
    />
  );
};
