import type { SubmissionParamsKeys } from './constants';

export const SUBMISSION_KEY = 'submissionId';
export type SubmissionParams = Record<SubmissionParamsKeys.SUBMISSION_ID, string>;

export const appPaths = {
  dashboard: () => `/dashboard`,
  home: () => `/`,
  login: () => `/login`,
  forgotPassword: () => `/forgot-password`,
  resetPassword: () => `/reset-password`,
  setupPassword: () => `/register`,
  singleSubmission: () => `submission/:${SUBMISSION_KEY}`,
};
