export const FiltersLoading = () => {
  return (
    <div className="animate-pulse border-t border-b border-gray-200 bg-gray-100 p-5">
      <h4 className="text-bas leading-6 font-semibold mb-2.5">Filters</h4>
      <div className="grid gap-x-5 gap-y-4 grid-cols-2">
        <div className="flex flex-col">
          <div className="h-3 bg-slate-200 rounded w-2/5 mb-2" />
          <div className="h-11 bg-slate-200 rounded" />
        </div>
        <div className="flex flex-col">
          <div className="h-3 bg-slate-200 rounded w-2/5 mb-2" />
          <div className="h-11 bg-slate-200 rounded" />
        </div>
        <div className="flex flex-col">
          <div className="h-3 bg-slate-200 rounded w-2/5 mb-2" />
          <div className="h-11 bg-slate-200 rounded" />
        </div>
        <div className="flex flex-col">
          <div className="h-3 bg-slate-200 rounded w-2/5 mb-2" />
          <div className="h-11 bg-slate-200 rounded" />
        </div>
        <div className="flex flex-col">
          <div className="h-3 bg-slate-200 rounded w-2/5 mb-2" />
          <div className="h-11 bg-slate-200 rounded" />
        </div>
        <div className="bg-slate-200 rounded w-3/4 ml-auto mt-5">
          <div className="h-11 bg-slate-200 rounded" />
        </div>
      </div>
    </div>
  );
};
