import { AuthLayout } from '../views/auth/auth-layout';
import { ForgotPasswordForm } from '../views/auth/forgot-password';

export const ForgotPasswordRoute = () => {
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
};
