import type { FC, ReactNode } from 'react';

import { XIcon } from '@heroicons/react/solid';
import ReactModal from 'react-modal';

import { H3 } from '../typography';
import { modalStyle } from './style';

interface ModalProps {
  children?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

ReactModal.setAppElement('#root');

export const Modal: FC<ModalProps> = ({ children, isOpen, onClose, title }) => {
  return (
    <ReactModal isOpen={isOpen} style={modalStyle}>
      <div className="pb-8 flex items-center justify-between">
        <H3>{title}</H3>
        <XIcon className="w-5 h-5 text-gray-400 cursor-pointer" onClick={onClose} />
      </div>
      <div className="flex flex-col">{children}</div>
    </ReactModal>
  );
};
