import { useState } from 'react';

import { useSWRConfig } from 'swr';

import type { Submission } from '../types';
import { put } from './use-query';

interface MutateDataArgs {
  data: Submission;
}

export const useArchiveSubmission = (submissionId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useSWRConfig();

  const execute = async () => {
    try {
      setIsLoading(true);
      return await mutate(
        `${process.env.REACT_APP_API_URL}/submissions/${submissionId}`,
        async ({ data: submission }: MutateDataArgs) => {
          if (!submission) {
            return undefined;
          }

          const shouldArchiveSubmission = submission.archived === 0;
          await put(`${process.env.REACT_APP_API_URL}/submissions/${submissionId}`, {
            archived: shouldArchiveSubmission,
          });

          return { data: { ...submission, archived: shouldArchiveSubmission } };
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    execute,
    isLoading,
  };
};
