import { useState } from 'react';
import type { FC } from 'react';

import Auth from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

import { Popper } from '../../components';
import { appPaths, toUppercaseFirstLetter } from '../../helpers';
import { useCurrentUser } from '../../hooks';
import { UserDetailsPoppover } from './components/UserDetailsPoppover';
import { UserInfoLoading } from './components/UserInfoLoading';

const getUserHoverStyle = (role: string) => {
  if (role === 'admin') {
    return 'bg-blue-600';
  }

  return 'bg-teal-600';
};

export const UserInfo: FC = () => {
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const [isPoppoverOpen, setIsPoppoverOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);

  if (!user) {
    return <UserInfoLoading />;
  }

  const handlePoppoverOpen = () => {
    setIsPoppoverOpen(true);
  };

  const handlePoppoverClose = () => {
    setIsPoppoverOpen(false);
  };

  const handleLogout = async () => {
    await Auth.signOut();
    return navigate(appPaths.login());
  };

  const firstName = user.attributes.given_name;
  const lastName = user.attributes.family_name;
  const role = user.attributes['custom:role'];

  const hoverStyle = getUserHoverStyle(role);

  return (
    <div className="flex items-center mr-11 ml-16">
      <div
        className={`rounded-full bg-gray-500 w-8 h-8 text-white flex items-center justify-center font-normal cursor-pointer transition text-sm hover:${hoverStyle}`}
        onClick={handlePoppoverOpen}
        ref={setReferenceElement}
      >
        {toUppercaseFirstLetter(firstName)}
        {toUppercaseFirstLetter(lastName)}
      </div>
      <Popper
        isOpen={isPoppoverOpen}
        offset={[0, 26]}
        onClose={handlePoppoverClose}
        placement="bottom-end"
        referenceElement={referenceElement}
      >
        <UserDetailsPoppover
          email={user.attributes.email}
          firstName={firstName}
          lastName={lastName}
          onLogout={handleLogout}
          userType={role}
        />
      </Popper>
    </div>
  );
};
