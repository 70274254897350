import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import { ToastContainer } from 'react-toastify';
import { SWRConfig } from 'swr';

import { getEnvVariable } from './helpers';
import { Router } from './routes';

const userPoolId = getEnvVariable('REACT_APP_COGNITO_USER_POOL_ID');
const webClientId = getEnvVariable('REACT_APP_COGNITO_WEB_CLIENT_ID');

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

Auth.configure({
  region: 'eu-west-1',
  userPoolId: userPoolId,
  userPoolWebClientId: webClientId,
});

export const App = () => {
  return (
    <SWRConfig value={{ provider: () => new Map() }}>
      <ToastContainer />
      <Router />
    </SWRConfig>
  );
};
