import { useEffect, useState } from 'react';

import { Auth } from '@aws-amplify/auth';

export interface AmplifyUser {
  signInUserSession: { idToken: { payload: string } };
  attributes: {
    given_name: string;
    family_name: string;
    email: string;
    sub: string[];
    'custom:role': string;
    'custom:tenantId': string;
    'custom:subTenantIds': string[];
  };
}

export const useCurrentUser = () => {
  const [user, setUser] = useState<AmplifyUser>();
  const [isLoading, setIsLoading] = useState(true);

  const authenticate = async () => {
    try {
      setIsLoading(true);
      const authUser = await Auth.currentAuthenticatedUser();
      setUser(authUser);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setUser(undefined);
      console.error(error);
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  return {
    isLoading,
    user,
  };
};
