import type { FC } from 'react';

import clsx from 'clsx';

import { toUppercaseFirstLetter } from '../../../../helpers';

interface UserCellProps {
  userActive: boolean;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
}

const getUserActiveStyle = (isActive: boolean) => {
  if (isActive) {
    return 'bg-green-500';
  }

  return 'bg-red-500';
};

export const UserCell: FC<UserCellProps> = ({ userActive, userEmail, userFirstName, userLastName }) => {
  const userDotColour = getUserActiveStyle(userActive);

  return (
    <>
      <div className="flex items-center">
        <div className="rounded-full bg-gray-500 w-10 h-10 inline-flex items-center justify-center text-white mr-4 text-base leading-none font-normal relative">
          {toUppercaseFirstLetter(userFirstName)}
          {toUppercaseFirstLetter(userLastName)}
          <div className={'absolute -right-0.5 bottom-0 bg-white rounded-full flex items-center justify-center p-0.5'}>
            <div className={clsx('rounded-full w-2.5 h-2.5', userDotColour)} />
          </div>
        </div>
        <div className="flex flex-col items-start">
          <p className="text-sm leading-5 font-medium text-gray-900">
            {userFirstName} {userLastName}
          </p>
          <p className="text-sm leading-5 font-normal text-gray-500">{userEmail}</p>
        </div>
      </div>
    </>
  );
};
