import type { FC } from 'react';

import { InboxInIcon } from '@heroicons/react/solid';

import { capitalizeFirstLetter } from '../../../helpers/capitalize-first-letter';

interface UploadTypeProps {
  type: string;
}

export const UploadType: FC<UploadTypeProps> = ({ type }) => {
  return (
    <div className="inline-flex items-center">
      <InboxInIcon className="w-4 h-4 text-gray-400 mr-2" />
      <p className="text-gray-500 text-sm leading-5 font-medium">{capitalizeFirstLetter(type)}</p>
    </div>
  );
};
