import type { FC, ReactElement } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { PageLoader } from '../components';
import { appPaths } from '../helpers';
import { useCurrentUser } from '../hooks';

interface RequireAuthenticationProps {
  children: ReactElement;
}

export const RequireAuthentication: FC<RequireAuthenticationProps> = ({ children }) => {
  const { isLoading, user } = useCurrentUser();
  const location = useLocation();

  if (isLoading) {
    return <PageLoader />;
  }

  return user ? children : <Navigate to={appPaths.login()} state={{ from: location }} />;
};
