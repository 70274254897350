import type { FC, ReactNode } from 'react';

import { isFunction } from 'lodash';

import type { FieldRenderProps } from './types';
import { useFieldInfo } from './utils/use-field-info';

interface FieldProps {
  children: ReactNode | ((renderProps: FieldRenderProps) => ReactNode);
  errorMessage?: string;
  hasError?: boolean;
  label?: string;
  touched?: boolean;
}

export const Field: FC<FieldProps> = ({ children, errorMessage, hasError = false, label, touched }) => {
  const { id, labelId, renderProps } = useFieldInfo({ label });
  const shouldShowError = Boolean((hasError || errorMessage) && touched);

  return (
    <div>
      <label className="text-sm leading-5 text-gray-700 font-medium inline-flex pb-1" htmlFor={id} id={labelId}>
        {label}
      </label>
      <div>{isFunction(children) ? children({ ...renderProps, hasError: shouldShowError }) : children}</div>
      {errorMessage && shouldShowError && (
        <p className="text-sm leading-5 font-normal text-red-600 pt-2">{errorMessage}</p>
      )}
    </div>
  );
};
