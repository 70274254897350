import React from 'react';

import { ControlledInput, Field, Form, H3 } from '../../../../components';
import type { ResolvedFormValues } from '../types';
import {
  atLeastOneNumberValidator,
  confirmationPasswordValidator,
  lowerAndUpperCaseValidator,
  minLengthValidator,
  specialCharactersValidator,
} from '../validators/validators';
import { PasswordRule } from './PasswordRule';
import { SubmitButton } from './SubmitButton';

const PasswordForm = ({ handleSubmit }: { handleSubmit: (values: ResolvedFormValues) => void }) => (
  <div className="flex flex-col">
    <H3 className="pb-2.5">Set your password</H3>
    <p className="text-sm leading-5 font-normal text-gray-700 pb-5">
      For security your password will need to match the following requirements:
    </p>
    <Form initialValues={{ newPassword: '', confirmNewPassword: '' }} onSubmit={handleSubmit}>
      <div className="flex flex-col pb-5">
        <PasswordRule label="Minimum of 10 characters" validator={minLengthValidator} />
        <PasswordRule label="Containing upper and lower case letters" validator={lowerAndUpperCaseValidator} />
        <PasswordRule label="Contain at least one number" validator={atLeastOneNumberValidator} />
        <PasswordRule label="Contain one special character" validator={specialCharactersValidator} />
        <PasswordRule label="Confirmation must match password" validator={confirmationPasswordValidator} />
      </div>
      <div className="pb-5">
        <Field label="New password">
          {(renderProps) => <ControlledInput {...renderProps} name="newPassword" type="password" />}
        </Field>
      </div>
      <div className="pb-5">
        <Field label="Confirm new password">
          {(renderProps) => <ControlledInput {...renderProps} name="confirmNewPassword" type="password" />}
        </Field>
      </div>
      <SubmitButton />
    </Form>
  </div>
);

export default PasswordForm;
