import type { ReactNode } from 'react';

import type { Config } from 'final-form';
import { isFunction } from 'lodash';
import type { FormRenderProps } from 'react-final-form';
import { Form as ReactFinalForm } from 'react-final-form';

export type FormSubmitHandler<FormValues> = Config<FormValues, Partial<FormValues>>['onSubmit'];

interface FormProps<FormValues = Record<string, unknown>> {
  children: ReactNode | ((renderProps: FormRenderProps<FormValues>) => ReactNode);
  initialValues?: Partial<FormValues>;
  onSubmit: FormSubmitHandler<FormValues>;
}

export const Form = <FormValues,>({ children, initialValues = {}, onSubmit }: FormProps<FormValues>) => {
  return (
    <ReactFinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {(props) => (
        <form onSubmit={props.handleSubmit} noValidate>
          {isFunction(children) ? children(props) : children}
        </form>
      )}
    </ReactFinalForm>
  );
};
