import type { FC } from 'react';

import clsx from 'clsx';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import { routePaths } from '../../../../../helpers';
import type { Submission } from '../../../../../types';
import { SubmissionStatus } from '../../../submission-status';
import { UploadType } from '../../../upload-type';
import { UserInfo } from '../../../user-info';

type SubmissionInboxItemProps = Submission;

const activeClassName = 'bg-blue-50 hover:bg-blue-50 border-l-4 border-blue-600 pl-4';

export const SubmissionInboxItem: FC<SubmissionInboxItemProps> = ({
  id,
  responseSent,
  status,
  subject,
  userEmail,
  userFirstName,
  userLastName,
  via,
}) => {
  const [searchParams] = useSearchParams();
  const { submissionId } = useParams();
  const isActive = id === submissionId;

  const userFullName = `${userFirstName} ${userLastName}`;

  return (
    <Link to={`${routePaths.singleSubmission({ submissionId: id })}?${searchParams}`}>
      <div
        className={clsx(
          'flex flex-col h-32 xl:p-5 lg:p-3 border-b border-b-gray-200 cursor-pointer hover:bg-gray-50',
          isActive && activeClassName
        )}
      >
        <div className="flex justify-between items-center pb-2.5">
          <h4
            className="text-base font-semibold text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis max-w-1/2"
            title={subject}
          >
            {subject}
          </h4>
          <p className="text-gray-600 font-normal ml-auto text-xs">{responseSent}</p>
        </div>
        <div className="flex items-center pb-2.5">
          <UserInfo email={userEmail} name={userFullName} />
        </div>
        <div className="flex justify-between items-center pb-2.5">
          <UploadType type={via} />
          <SubmissionStatus status={status} />
        </div>
      </div>
    </Link>
  );
};
