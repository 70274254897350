import type { FC } from 'react';

import { ProcessingGaugeCard } from './ProcessingGaugeCard';

interface ProcessingGaugesProps {
  goldenRulePassRateFailed: number;
  goldenRulePassRatePassed: number;
  submissionFailed: number;
  submissionMethodEmail: number;
  submissionMethodWeb: number;
  submissionProcessed: number;
}

export const ProcessingGauges: FC<ProcessingGaugesProps> = ({
  goldenRulePassRateFailed,
  goldenRulePassRatePassed,
  submissionFailed,
  submissionMethodEmail,
  submissionMethodWeb,
  submissionProcessed,
}) => {
  const submissionsMethodData = [
    { color: '#38BDF8', label: 'Upload', value: submissionMethodWeb },
    { color: '#F472B6', label: 'Email', value: submissionMethodEmail },
  ];

  const submissionsProcessedData = [
    { color: '#F87171', label: 'Failed', value: submissionFailed },
    { color: '#34D399', label: 'Processed', value: submissionProcessed },
  ];

  const goldenRulePassRateData = [
    { color: '#22D3EE', label: 'Failed', value: goldenRulePassRateFailed },
    { color: '#FBBF24', label: 'Passed', value: goldenRulePassRatePassed },
  ];

  return (
    <div className="flex flex-col pt-7">
      <h4 className="text-lg leading-6 font-medium text-gray-900 pb-4">Processing Gauges</h4>
      <div className="grid gap-x-5 grid-cols-3">
        <ProcessingGaugeCard data={submissionsMethodData} label="Submission method" />
        <ProcessingGaugeCard data={submissionsProcessedData} label="Submissions processed" />
        <ProcessingGaugeCard data={goldenRulePassRateData} label="Golden rule pass rate" />
      </div>
    </div>
  );
};
