import { transparentize } from 'polished';

export const modalStyle = {
  content: {
    width: '510px',
    top: '265px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    transform: 'translate(-50%)',
    borderRadius: '8px',
    boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    border: 'none',
  },
  overlay: {
    background: transparentize(0.5, '#4B5563'),
  },
};
