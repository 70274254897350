export const DashboardLoading = () => {
  return (
    <div className="animate-pulse flex p-5 flex-col">
      <div className="flex-1 space-y-6 py-1">
        <div className="grid grid-cols-6">
          <div className="h-6 bg-slate-200 rounded-lg col-span-1" />
        </div>
        <div className="space-y-3 pb-9">
          <div className="grid grid-cols-4 gap-4">
            <div className="h-24 bg-slate-200 rounded col-span-1" />
            <div className="h-24 bg-slate-200 rounded col-span-1" />
            <div className="h-24 bg-slate-200 rounded col-span-1" />
            <div className="h-24 bg-slate-200 rounded col-span-1" />
          </div>
        </div>
      </div>

      <div className="flex-1 space-y-6 py-1">
        <div className="grid grid-cols-6">
          <div className="h-6 bg-slate-200 rounded-lg col-span-1" />
        </div>
        <div className="space-y-3 pb-9">
          <div className="grid grid-cols-4 gap-4">
            <div className="h-80 bg-slate-200 rounded col-span-1" />
            <div className="h-80 bg-slate-200 rounded col-span-1" />
            <div className="h-80 bg-slate-200 rounded col-span-1" />
            <div className="h-80 bg-slate-200 rounded col-span-1" />
          </div>
        </div>
      </div>

      <div className="flex-1 space-y-6 py-1">
        <div className="grid grid-cols-6">
          <div className="h-6 bg-slate-200 rounded-lg col-span-1" />
        </div>
        <div className="pb-9">
          <div className="h-16 bg-slate-400 border-b border-gray-300" />
          <div className="h-16 bg-slate-200 border-b border-gray-300" />
          <div className="h-16 bg-slate-200 border-b border-gray-300" />
          <div className="h-16 bg-slate-200 border-b border-gray-300" />
          <div className="h-16 bg-slate-200 border-b border-gray-300" />
        </div>
      </div>
    </div>
  );
};
