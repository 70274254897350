import { useState } from 'react';

import { BodyCell } from './components/BodyCell';
import { HeaderCell } from './components/HeaderCell';
import type { SortOptions, TableCell, TableColumn } from './types';
import { SortDirection } from './types';

interface TableProps<Data> {
  columns: TableColumn<Data>[];
  data: TableCell<Data>[][];
}

const getSortedData = <Data,>(data: TableCell<Data>[][], sortOptions?: SortOptions<Data>): TableCell<Data>[][] => {
  if (!sortOptions) {
    return data;
  }

  return [...data].sort((row1, row2) => {
    const item1 = row1.find((cell) => cell.id === sortOptions.sortBy);
    const item2 = row2.find((cell) => cell.id === sortOptions.sortBy);

    if (!item1) {
      return 1;
    }

    if (!item2) {
      return -1;
    }

    if (sortOptions.sortDirection === SortDirection.Desc) {
      return item1.value <= item2.value ? 1 : -1;
    }

    return item1.value >= item2.value ? 1 : -1;
  });
};

export const Table = <Data,>({ columns, data }: TableProps<Data>) => {
  const [sortBy, setSortBy] = useState<SortOptions<Data>>();

  const handleSortBy = (id: keyof Data, direction?: SortDirection) => {
    if (!direction) {
      return setSortBy(undefined);
    }

    return setSortBy({ sortBy: id, sortDirection: direction });
  };

  const sortedData = getSortedData(data, sortBy);

  return (
    <table
      className="table-auto border-collapse rounded-lg w-full text-sm border border-gray-200"
      style={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)' }}
    >
      <thead className="bg-gray-100 rounded-lg border-b border-gray-100">
        {columns.map(({ alignment, id, label }) => (
          <HeaderCell
            alignment={alignment}
            id={id}
            key={id as string}
            onSort={handleSortBy}
            sortDirection={sortBy?.sortBy === id ? sortBy?.sortDirection : undefined}
          >
            {label}
          </HeaderCell>
        ))}
      </thead>
      <tbody className="bg-white">
        {sortedData.map((row) => (
          <tr key={`table-row-${row[0].id as string}`}>
            {row.map((cell) => (
              <BodyCell key={cell.id as string}>{cell.render?.(cell.value) ?? cell.value}</BodyCell>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
