import type { FC, ReactNode } from 'react';

import clsx from 'clsx';

interface H3Props {
  children: ReactNode;
  className?: string;
}

export const H3: FC<H3Props> = ({ children, className }) => {
  return <h3 className={clsx('text-xl font-semibold flex items-center', className)}>{children}</h3>;
};
