import type { FC } from 'react';

import { ClockIcon, DocumentDuplicateIcon, MenuIcon, ThumbUpIcon } from '@heroicons/react/outline';

import { formatNumber } from '../../../../helpers';
import { ActivityCard } from './ActivityCard';

interface ActivityInPeriodProps {
  avgConfidenceRating: number;
  avgProcessingTime: number;
  rows: number;
  submissions: number;
}

export const ActivityInPeriod: FC<ActivityInPeriodProps> = ({
  avgConfidenceRating,
  avgProcessingTime,
  rows,
  submissions,
}) => {
  return (
    <div className="flex flex-col">
      <h4 className="text-lg leading-6 font-medium text-gray-900 pb-4">Activity in Period</h4>
      <div className="grid gap-x-5 grid-cols-4">
        <ActivityCard
          icon={<DocumentDuplicateIcon className="w-5 h-5" />}
          label="Submissions"
          value={String(submissions)}
        />
        <ActivityCard icon={<MenuIcon className="w-5 h-5" />} label="Total rows" value={formatNumber(rows)} />
        <ActivityCard
          icon={<ClockIcon className="w-5 h-5" />}
          label="Avg. processing time"
          value={`${avgProcessingTime} sec`}
        />
        <ActivityCard
          icon={<ThumbUpIcon className="w-5 h-5" />}
          label="Avg. model prediction score"
          value={`${Number(avgConfidenceRating).toFixed(2)}`}
        />
      </div>
    </div>
  );
};
