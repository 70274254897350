import type { ButtonAlignment, ButtonColorScheme, ButtonVariant } from './types';

export const mapVariantToButtonColorScheme = (variant: ButtonVariant): ButtonColorScheme =>
  ({
    ghost: {
      css: 'bg-white border border-gray-300 hover:bg-gray-100 disabled:opacity-50',
      text: 'text-gray-700',
    },
    primary: {
      css: 'bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-50 disabled:hover:bg-blue-500',
      text: '',
    },
    secondary: {
      css: 'bg-gray-400 text-white hover:bg-gray-500',
      text: '',
    },
  }[variant]);

export const mapAlignmentToCssStyle = (alignment: ButtonAlignment): string =>
  ({
    center: 'justify-center',
    left: 'justify-start',
    right: 'justify-end',
  }[alignment]);
