import Auth from '@aws-amplify/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { appPaths } from '../../../helpers';
import { parseUrlParams } from '../../../helpers/parse-url-params';
import PasswordForm from './components/PasswordForm';
import type { ResolvedFormValues } from './types';

export const ResetPasswordForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async (values: ResolvedFormValues) => {
    try {
      const { email, code } = parseUrlParams(location.search);

      await Auth.forgotPasswordSubmit(email as string, code as string, values.newPassword);

      return navigate(appPaths.login());
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return <PasswordForm handleSubmit={handleSubmit} />;
};
