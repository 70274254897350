import type { FC } from 'react';

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { useFormState } from 'react-final-form';

import type { FormValues, PasswordRuleValidator } from '../types';

interface PasswordRuleProps {
  label: string;
  validator: PasswordRuleValidator;
}

const SuccessIcon = () => <CheckCircleIcon className="text-green-500" />;
const ErrorIcon = () => <XCircleIcon className="text-red-500" />;

export const PasswordRule: FC<PasswordRuleProps> = ({ label, validator }) => {
  const { values } = useFormState<FormValues>();

  const isSatisfied = validator(values);
  const IconComponent = isSatisfied ? SuccessIcon : ErrorIcon;

  return (
    <div className="flex items-center">
      <div className="flex items-center w-4 h-4 mr-3">
        <IconComponent />
      </div>
      <p className="text-sm leading-5 font-normal text-gray-700">{label}</p>
    </div>
  );
};
