import type { FC, ReactNode } from 'react';

interface ActivityCardProps {
  icon: ReactNode;
  label: string;
  value: string;
}

export const ActivityCard: FC<ActivityCardProps> = ({ icon, label, value }) => {
  return (
    <div
      className="flex items-center rounded-lg bg-white p-6"
      style={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)' }}
    >
      <div className="flex items-center justify-center bg-blue-600 text-white rounded-md w-12 h-12 mr-5">{icon}</div>
      <div className="flex flex-col">
        <p className="text-sm/leading-5/font-medium text-gray-500">{label}</p>
        <p className="text-2xl leading-8 font-semibold text-gray-900">{value}</p>
      </div>
    </div>
  );
};
