import type { FC } from 'react';

import { ExclamationIcon, UploadIcon, XCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { SubmissionStatus } from '../../../../types';

interface SubmissionDetailsBannerProps {
  status: SubmissionStatus;
}

type SubmissionNotProcessedStatus = Exclude<SubmissionStatus, 'Processed'>;

const getBannerText = (status: SubmissionNotProcessedStatus) => {
  if (status === SubmissionStatus.Uploading) {
    return 'Schedule file is being uploaded, check back shortly';
  }

  if (status === SubmissionStatus.Processing) {
    return 'Schedule file is still being processed, check back shortly';
  }

  if (status === SubmissionStatus.Failed) {
    return 'Could not find all of the required columns within the schedule file';
  }

  return undefined;
};

const getBannerClassName = (status: SubmissionNotProcessedStatus) => {
  if (status === SubmissionStatus.Failed) {
    return 'text-red-900 bg-red-50';
  }

  if (status === SubmissionStatus.Processing) {
    return 'text-yellow-800 bg-yellow-100';
  }

  if (status === SubmissionStatus.Uploading) {
    return 'text-blue-800 bg-blue-100';
  }
};

const getBannerIcon = (status: SubmissionNotProcessedStatus) => {
  if (status === SubmissionStatus.Uploading) {
    return <UploadIcon className="text-blue-800 w-5 h-5" />;
  }

  if (status === SubmissionStatus.Processing) {
    return <ExclamationIcon className="text-yellow-400 w-5 h-5" />;
  }

  if (status === SubmissionStatus.Failed) {
    return <XCircleIcon className="text-red-400 w-5 h-5" />;
  }
};

export const SubmissionDetailsBanner: FC<SubmissionDetailsBannerProps> = ({ status }) => {
  if (status === 'Processed') {
    return null;
  }

  const text = getBannerText(status);
  const bannerClassName = getBannerClassName(status);
  const IconComponent = getBannerIcon(status);

  return (
    <div className={clsx('rounded-md flex mt-7.5 p-4 gap-x-3.5 items-center', bannerClassName)}>
      {IconComponent}
      {text}
    </div>
  );
};
