import type { FC } from 'react';

import { Dashboard } from '../views/dashboard/dashboard';
import { Navbar } from '../views/navbar';

export const DashboardRoute: FC = () => {
  return (
    <div className="h-full">
      <Navbar />
      <Dashboard />
    </div>
  );
};
