import type { FC } from 'react';

import { PaperClipIcon } from '@heroicons/react/outline';
import { ArchiveIcon, DocumentDownloadIcon, RefreshIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button } from '../../../../../components';
import { useArchiveSubmission } from '../../../../../data/use-archive-submission';
import { useDownloadProcessedFile } from '../../../../../data/use-download-processed-file';
import { useGetAttachmentFile } from '../../../../../data/use-get-attachment-file';
import { SubmissionStatus } from '../../../../../types';

interface SubmissionActionsProps {
  isArchived: boolean;
  fileName: string;
  processedFileName?: string;
  status: SubmissionStatus;
  submissionId: string;
}

export const SubmissionActions: FC<SubmissionActionsProps> = ({
  isArchived,
  fileName,
  processedFileName,
  status,
  submissionId,
}) => {
  const { execute: getAttachmentFile, isLoading: isAttachmentLoading } = useGetAttachmentFile(submissionId, fileName);
  const { execute: getProcessedFile, isLoading: isProcessedFileLoading } = useDownloadProcessedFile(
    submissionId,
    processedFileName
  );
  const { execute: archiveSubmission, isLoading: isArchiving } = useArchiveSubmission(submissionId);

  const isProcessed = status === SubmissionStatus.Processed;

  const handleArchiveSubmission = async () => {
    const result = await archiveSubmission();
    if (result) {
      toast.success(`Submission has been ${result.data.archived === 1 ? 'archived' : 'unarchived'}!`, {
        position: 'top-center',
      });
    }
  };

  return (
    <div className="flex gap-x-5 py-5">
      <Button
        iconBefore={<PaperClipIcon />}
        isDisabled={isAttachmentLoading || isArchived}
        onClick={getAttachmentFile}
        variant="ghost"
      >
        Original File
      </Button>
      <Button
        iconBefore={isArchived ? <RefreshIcon /> : <ArchiveIcon />}
        isDisabled={isArchiving}
        onClick={handleArchiveSubmission}
        variant="ghost"
      >
        {isArchived ? 'Unarchive' : 'Archive'} Submission
      </Button>
      <Button
        className="ml-auto"
        iconBefore={<DocumentDownloadIcon />}
        isDisabled={!isProcessed || isProcessedFileLoading || isArchived}
        onClick={getProcessedFile}
        variant="primary"
      >
        Download Processed File
      </Button>
    </div>
  );
};
