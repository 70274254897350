import type { FC } from 'react';

import { Outlet } from 'react-router-dom';

import { Navbar } from '../views/navbar';
import { SubmissionsPanel } from '../views/submissions/submissions-panel/submissions-panel';

export const SubmissionsRoute: FC = () => {
  return (
    <div className="h-full overflow-hidden">
      <Navbar />
      <div className="flex h-full">
        <div className="basis-1/3 max-w-1/3 border-r border-gray-300">
          <SubmissionsPanel />
        </div>
        <div className="basis-2/3 xl:p-10 overflow-auto lg:p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
