import type { FC } from 'react';

import { useField } from 'react-final-form';

import type { InputProps } from './Input';
import { Input } from './Input';

interface ControlledInputProps extends InputProps {
  name: string;
  validate?: (value: string | number) => string | undefined;
}

export const ControlledInput: FC<ControlledInputProps> = ({ name, validate, ...rest }) => {
  const { input } = useField(name, { validate });

  return <Input {...rest} {...input} />;
};
