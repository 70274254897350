export const SubmissionLoading = () => {
  return (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-6 py-1">
        <div className="grid grid-cols-6">
          <div className="h-8 bg-slate-200 rounded-lg col-span-4" />
        </div>
        <div className="space-y-3 pb-9 border-b border-gray-200">
          <div className="grid grid-cols-12 gap-4">
            <div className="h-3 bg-slate-200 rounded col-span-2" />
            <div className="h-3 bg-slate-200 rounded col-span-2" />
            <div className="h-3 bg-slate-200 rounded col-span-2" />
            <div className="h-3 bg-transparent rounded col-span-4" />
            <div className="h-3 bg-slate-200 rounded col-span-2" />
          </div>
        </div>
        <div className="flex pb-10 border-b border-gray-200">
          <div className="basis-3/4">
            <div className="h-6 bg-slate-200 rounded w-1/2 mb-7" />
            <div className="flex flex-col">
              <div className="flex py-5 items-center">
                <div className="basis-1/2">
                  <div className="h-3 bg-slate-200 rounded w-1/2" />
                </div>
                <div className="basis-1/2">
                  <div className="h-3 bg-slate-200 rounded w-1/2" />
                </div>
              </div>
              <div className="flex py-5 items-center">
                <div className="basis-1/2">
                  <div className="h-3 bg-slate-200 rounded w-1/2" />
                </div>
                <div className="basis-1/2">
                  <div className="h-3 bg-slate-200 rounded w-1/2" />
                </div>
              </div>
              <div className="flex py-5 items-center">
                <div className="basis-1/2">
                  <div className="h-3 bg-slate-200 rounded w-1/2" />
                </div>
                <div className="basis-1/2">
                  <div className="h-3 bg-slate-200 rounded w-1/2" />
                </div>
              </div>
            </div>
          </div>
          <div className="basis-1/4">
            <div className="h-6 bg-slate-200 rounded w-3/4 mb-7" />
            <div className="flex flex-col px-4 py-3 bg-gray-100 rounded-lg mb-5">
              <div className="h-3 bg-slate-200 rounded w-1/4 mb-2" />
              <div className="h-7 bg-slate-200 rounded w-2/4" />
            </div>
            <div className="flex flex-col px-4 py-3 bg-gray-100 rounded-lg mb-5">
              <div className="h-3 bg-slate-200 rounded w-1/4 mb-2" />
              <div className="h-7 bg-slate-200 rounded w-2/4" />
            </div>
            <div className="flex flex-col px-4 py-3 bg-gray-100 rounded-lg">
              <div className="h-3 bg-slate-200 rounded w-1/4 mb-2" />
              <div className="h-7 bg-slate-200 rounded w-2/4" />
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="h-11 bg-slate-200 rounded basis-1/5 mr-5">
            <div className="h-5 bg-slate-200 rounded w-1/2" />
          </div>
          <div className="h-11 bg-slate-200 rounded basis-1/5">
            <div className="h-5 bg-slate-200 rounded w-1/2" />
          </div>
          <div className="h-11 bg-slate-200 rounded basis-1/5 ml-auto">
            <div className="h-5 bg-slate-200 rounded w-3/4" />
          </div>
        </div>
      </div>
    </div>
  );
};
