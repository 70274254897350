import { AuthLayout } from '../views/auth/auth-layout';
import { ResetPasswordForm } from '../views/auth/setup-password';

export const ResetPasswordRoute = () => {
  return (
    <AuthLayout>
      <ResetPasswordForm />
    </AuthLayout>
  );
};
