import { useMemo } from 'react';

let uniq = 0;

const generateUniqueId = (prefix?: string) => {
  uniq++;
  return prefix ? `${prefix}-${uniq}` : uniq.toString();
};

export const useUniqueIdentifier = (prefix?: string) => {
  const id = generateUniqueId(prefix);
  return useMemo(() => id, [id]);
};
