import type { FC } from 'react';
import React from 'react';

import { CalendarIcon } from '@heroicons/react/solid';

import { ClearIcon } from './ClearIcon';

interface DateIconsProps {
  hasValue: boolean;
  onClear: () => void;
}

export const DateIcons: FC<DateIconsProps> = ({ hasValue, onClear }) => {
  return (
    <div className="flex gap-x-1">
      {hasValue && <ClearIcon onClick={onClear} />}
      <CalendarIcon className="w-4 h-4" style={{ marginTop: hasValue ? '1px' : '0' }} />
    </div>
  );
};
