import { useFormState } from 'react-final-form';

import { Button } from '../../../../components';
import {
  atLeastOneNumberValidator,
  confirmationPasswordValidator,
  lowerAndUpperCaseValidator,
  minLengthValidator,
  specialCharactersValidator,
} from '../validators/validators';

const validationChain = [
  minLengthValidator,
  lowerAndUpperCaseValidator,
  atLeastOneNumberValidator,
  specialCharactersValidator,
  confirmationPasswordValidator,
];

export const SubmitButton = () => {
  const { submitting, values } = useFormState();
  const isDisabled = validationChain.some((validator) => !validator(values)) || submitting;

  return (
    <Button isDisabled={isDisabled} shouldFitContainer variant="primary" type="submit">
      Set new password
    </Button>
  );
};
