import type { FC, MouseEventHandler } from 'react';
import React, { useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { DropzoneEmpty } from './components/DropzoneEmpty';
import { DropzoneFileUploaded } from './components/DropzoneFileUploaded';

interface DropzoneState {
  file: File;
  id: string;
}

interface DropzoneProps {
  isUploading?: boolean;
  onChange?: (file?: File) => void;
}

export const Dropzone: FC<DropzoneProps> = ({ isUploading = false, onChange }) => {
  const [selectedFile, setSelectedFile] = useState<DropzoneState>();

  const onDrop = ([acceptedFile]: File[]) => {
    setSelectedFile({ file: acceptedFile, id: 'some-unique-id' });
    onChange?.(acceptedFile);
  };

  const { getRootProps, getInputProps, open } = useDropzone({ multiple: false, noClick: true, onDrop });

  const handleDeleteFile: MouseEventHandler = (event) => {
    event.stopPropagation();
    setSelectedFile(undefined);
    onChange?.(undefined);
  };

  return (
    <section className="container">
      <div
        {...getRootProps({
          className: 'dropzone border-gray-300 border-2 h-36 flex border-dashed rounded-md cursor-pointer',
        })}
        onClick={open}
      >
        <input {...getInputProps()} />
        {!selectedFile && <DropzoneEmpty />}
        {selectedFile && (
          <DropzoneFileUploaded
            key={selectedFile.id}
            isUploading={isUploading}
            name={selectedFile.file.name}
            onDelete={handleDeleteFile}
          />
        )}
      </div>
    </section>
  );
};
