import type { FC } from 'react';
import { useState } from 'react';

import { XCircleIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';

import { Button, ControlledInput, Dropzone, Field, Form, Modal } from '../../components';
import { useUploadSubmissionFile } from '../../data/use-upload-submission-file';
import { routePaths } from '../../helpers';

interface NewSubmissionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  submissionTitle: string;
}

const getFileSizeInMegabits = (size: number) => size * 8 * Math.pow(10, -6);

const ALLOWED_FILE_EXTENSIONS = ['xlsx', 'xls'];
const MAX_FILE_SIZE_IN_MEGABITS = 800;

export const NewSubmissionModal: FC<NewSubmissionModalProps> = ({ isOpen, onClose }) => {
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState<string>();
  const { execute, isLoading } = useUploadSubmissionFile();
  const navigate = useNavigate();

  const handleSubmit = async (values: FormValues) => {
    try {
      if (!file) {
        return setFileError('File upload failed, please try again');
      }

      const fileSize = getFileSizeInMegabits(file.size);

      if (fileSize > MAX_FILE_SIZE_IN_MEGABITS) {
        return setFileError('File upload failed: File size exceeds 100Mb');
      }

      const fileExtension = file.name.split('.').pop();

      if (fileExtension && !ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
        return setFileError('Incorrect file type, please try again with a valid Excel file');
      }

      const { submissionId } = await execute(file as File, values.submissionTitle);
      onClose();
      return navigate(routePaths.singleSubmission({ submissionId }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileAdded = (uploadedFile?: File) => {
    if (!uploadedFile) {
      setFileError(undefined);
    }

    setFile(uploadedFile);
  };

  const submissionTitleValidator = (value: unknown) => (value ? undefined : 'The submission requires a title ');

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Create new submission">
      <Form initialValues={{ submissionTitle: '' }} onSubmit={handleSubmit}>
        {({ errors, touched }) => (
          <>
            <div className="pb-6">
              <Field errorMessage={errors?.submissionTitle} label="Submission title" touched={touched?.submissionTitle}>
                {(renderProps) => (
                  <ControlledInput
                    {...renderProps}
                    name="submissionTitle"
                    validate={submissionTitleValidator}
                    required
                  />
                )}
              </Field>
            </div>
            {fileError && (
              <div className="flex bg-red-50 rounded-md py-5 px-4 items-center mt-2 mb-7">
                <XCircleIcon className="w-5 h-5 mr-3 text-red-400" />
                <p className="text-sm leading-5 font-medium text-red-800">{fileError}</p>
              </div>
            )}
            <Field label="Upload a schedule file">
              {() => <Dropzone isUploading={isLoading} onChange={handleFileAdded} />}
            </Field>
            <div className="flex justify-end gap-5 pt-7">
              <Button type="button" onClick={onClose} variant="ghost">
                Cancel
              </Button>
              <Button isDisabled={!file || isLoading} type="submit" variant="primary">
                Create submission
              </Button>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};
