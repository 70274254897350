import type { DashboardStatistics } from '../types';
import { useQuery } from './use-query';

export const useDashboardStatistics = (filters: URLSearchParams) => {
  const { data, error, isLoading } = useQuery<DashboardStatistics>(`/stats?${filters}`);

  return {
    data,
    error,
    isLoading,
  };
};
