import Auth from '@aws-amplify/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { appPaths } from '../../../helpers';
import { parseUrlParams } from '../../../helpers/parse-url-params';
import PasswordForm from './components/PasswordForm';
import type { ResolvedFormValues } from './types';

export const SetupPasswordForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async (values: ResolvedFormValues) => {
    try {
      const { inviteCode, userId } = parseUrlParams(location.search);
      const user = await Auth.signIn(userId as string, inviteCode as string);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, values.newPassword);

        return navigate(appPaths.home());
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return <PasswordForm handleSubmit={handleSubmit} />;
};
