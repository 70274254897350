import type { FC } from 'react';

import clsx from 'clsx';

import type { SubmissionStatus } from '../../../types';

interface SubmissionStatusPillProps {
  status: SubmissionStatus;
}

const getPillStyle = (status: SubmissionStatus) =>
  ({
    ['Failed']: 'bg-red-100 text-red-800',
    ['Processed']: 'bg-green-100 text-green-800',
    ['Processing']: 'bg-yellow-100 text-yellow-800',
    ['Uploading']: 'bg-blue-100 text-blue-800',
  }[status]);

export const SubmissionStatusPill: FC<SubmissionStatusPillProps> = ({ status }) => {
  return (
    <div className={clsx('rounded-xl py-0.5 px-3 text-sm leading-5 font-medium', getPillStyle(status))}>{status}</div>
  );
};
