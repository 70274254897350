import type { FC } from 'react';

import { UserCircleIcon } from '@heroicons/react/solid';

interface UserInfoProps {
  email?: string;
  name: string;
}

export const UserInfo: FC<UserInfoProps> = ({ email, name }) => {
  const formattedEmail = email ? `<${email}>` : undefined;
  return (
    <div className="flex items-center">
      <UserCircleIcon className="w-4 h-4 text-gray-400 mr-2" />
      <p className="text-gray-500 text-sm leading-5 font-medium">
        {name} {formattedEmail}
      </p>
    </div>
  );
};
