import type { FC, InputHTMLAttributes, ReactNode } from 'react';

import clsx from 'clsx';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  iconAfter?: ReactNode;
  textClassName?: string;
}

export const Input: FC<InputProps> = ({
  hasError,
  iconAfter,
  textClassName = 'text-gray-900',
  value,
  ...inputProps
}) => {
  return (
    <div className="relative">
      <input
        {...inputProps}
        className={clsx(
          'shadow-sm border focus:border-gray-500 border-gray-300 w-full rounded-md p-2 placeholder:text-sm placeholder:leading-5 placeholder:font-normal placeholder:text-gray-400 text-sm leading-5 font-normal',
          iconAfter && 'pr-2.5',
          hasError && 'border-2 border-red-600',
          value && !hasError && 'border-gray-500',
          textClassName
        )}
        value={value}
      />
      {iconAfter && (
        <div
          className={clsx(
            'absolute inline-flex items-center justify-center top-0 bottom-0 right-2.5',
            value ? 'text-gray-600' : 'text-gray-400'
          )}
        >
          {iconAfter}
        </div>
      )}
    </div>
  );
};
