import type { Submission } from '../types';
import { useQuery } from './use-query';

export const useSubmissions = (filters: URLSearchParams) => {
  const { data, error, isLoading } = useQuery<Submission[]>(`/submissions?${filters}`);

  return {
    data,
    error,
    isLoading,
  };
};
