import type { FC } from 'react';
import { useEffect, useState } from 'react';

import type { PopperCoreProps } from './components/PopperCore';
import { PopperCore } from './components/PopperCore';

interface PopperProps extends Omit<PopperCoreProps, 'popperElement' | 'setPopperElement'> {
  isOpen: boolean;
  onClose?: () => void;
}

export const Popper: FC<PopperProps> = ({ children, isOpen, onClose, referenceElement, ...poppoverProps }) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!onClose) return;

    const handler = (ev: MouseEvent) => {
      if (!popperElement || !referenceElement || !(ev.target instanceof Node)) return;

      const isWithinPopover = popperElement.contains(ev.target);
      const isWithinAnchor = referenceElement instanceof Element ? referenceElement.contains(ev.target) : false;

      if (!isWithinPopover && !isWithinAnchor) {
        onClose();
      }
    };

    window.addEventListener('mousedown', handler);
    return () => window.removeEventListener('mousedown', handler);
  }, [referenceElement, onClose, popperElement]);

  if (!isOpen) {
    return null;
  }

  return (
    <PopperCore
      {...poppoverProps}
      popperElement={popperElement}
      referenceElement={referenceElement}
      setPopperElement={setPopperElement}
    >
      {children}
    </PopperCore>
  );
};
