import type { FC, MouseEventHandler } from 'react';

import { DocumentTextIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { Spinner } from './Spinner';

interface DropzoneFileUploadedProps {
  isUploading: boolean;
  name: string;
  onDelete: MouseEventHandler<SVGSVGElement>;
}

export const DropzoneFileUploaded: FC<DropzoneFileUploadedProps> = ({ isUploading, name, onDelete }) => {
  return (
    <div className="flex flex-col p-6 w-full">
      <div className="flex items-center gap-x-2.5">
        {isUploading ? <Spinner /> : <DocumentTextIcon className="w-5 h-5 text-blue-600" />}
        <p className={clsx('text-sm leading-4 font-medium text-gray-900', isUploading && 'text-gray-400')}>{name}</p>
        <XCircleIcon
          aria-label="Delete file"
          className="ml-auto w-4 h-4 text-gray-600 cursor-pointer"
          onClick={onDelete}
        />
      </div>
    </div>
  );
};
