import type { FC, HTMLProps, ReactNode } from 'react';

import clsx from 'clsx';

import { IconBefore } from './IconBefore';
import { mapAlignmentToCssStyle, mapVariantToButtonColorScheme } from './mappers';
import type { ButtonAlignment, ButtonVariant } from './types';

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  alignment?: ButtonAlignment;
  children?: ReactNode;
  iconBefore?: ReactNode;
  isDisabled?: boolean;
  shouldFitContainer?: boolean;
  variant?: ButtonVariant;
}

export const Button: FC<ButtonProps> = ({
  alignment = 'center',
  children,
  className,
  iconBefore,
  isDisabled,
  onClick,
  shouldFitContainer = false,
  style,
  variant = 'primary',
}) => {
  const buttonColorScheme = mapVariantToButtonColorScheme(variant);
  const alignmentCss = mapAlignmentToCssStyle(alignment);

  return (
    <button
      className={clsx(
        'shadow-sm px-4 py-5 rounded-md h-9 flex items-center flex ease-in-out duration-200 disabled:cursor-not-allowed text-sm leading-5 font-medium',
        buttonColorScheme.css,
        buttonColorScheme.text,
        shouldFitContainer && 'w-full',
        alignmentCss,
        className
      )}
      disabled={isDisabled}
      onClick={onClick}
      style={style}
    >
      {iconBefore ? <IconBefore className={buttonColorScheme.text}>{iconBefore}</IconBefore> : undefined}
      {children}
    </button>
  );
};
