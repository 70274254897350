import type { FC } from 'react';
import { useState } from 'react';
import React from 'react';

import { Chart } from 'primereact/chart';

interface ProcessingGaugeCardProps {
  data: Array<{ color: string; label: string; value: number }>;
  label: string;
}

const CHART_OPTIONS = {
  plugins: {
    legend: false,
  },
};

export const ProcessingGaugeCard: FC<ProcessingGaugeCardProps> = ({ data, label }) => {
  const mappedData = data.map(({ value }) => value);
  const [chartData] = useState({
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: mappedData,
        backgroundColor: data.map(({ color }) => color),
        hoverBackgroundColor: data.map(({ color }) => color),
      },
    ],
  });

  return (
    <div className="rounded-lg bg-white p-5 flex flex-col">
      <h4 className="text-lg leading-6 font-medium text-black pb-5">{label}</h4>
      <Chart
        className="self-center"
        type="doughnut"
        data={chartData}
        style={{ position: 'relative' }}
        height="287.5px"
        width="287.5px"
        options={CHART_OPTIONS}
      />
      <div className="flex justify-center align-center pt-5">
        {data.map(({ color, label }) => (
          <React.Fragment key={label}>
            <div className="rounded h-2.5 w-2.5 mr-2.5" style={{ backgroundColor: color }} />
            <span className="text-xs leading-none font-normal text-gray-600 mr-12">{label}</span>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
