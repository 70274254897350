import type { FC } from 'react';

interface RowProps {
  label: string;
  value: string;
}

export const Row: FC<RowProps> = ({ label, value }) => {
  return (
    <div className="flex border-b border-gray-200 py-5">
      <p className="basis-1/2 text-sm leading-5 font-medium text-gray-500">{label}</p>
      <p className="basis-1/2 text-sm leading-5 font-normal text-gray-900">{value}</p>
    </div>
  );
};
