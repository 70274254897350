import type { ReactNode } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { TableCellAlignment } from '../types';
import { SortDirection } from '../types';

interface HeaderCellProps<Data> {
  alignment?: TableCellAlignment;
  children: ReactNode;
  className?: string;
  id: keyof Data;
  onSort: (id: keyof Data, sortDirection?: SortDirection) => void;
  sortDirection?: SortDirection;
}

const getNextSortDirection = (direction?: SortDirection) => {
  if (!direction) {
    return SortDirection.Asc;
  }

  if (direction === SortDirection.Asc) {
    return SortDirection.Desc;
  }

  return undefined;
};

export const HeaderCell = <Data,>({
  alignment = TableCellAlignment.Center,
  children,
  id,
  onSort,
  sortDirection,
}: HeaderCellProps<Data>) => {
  return (
    <th className={`text-xs leading-4 font-medium tracking-wider uppercase py-4 px-6 text-gray-500 text-${alignment}`}>
      {children}
      <div
        className="inline-flex flex-col items-center text-gray-300 ml-2.5 cursor-pointer"
        onClick={() => onSort(id, getNextSortDirection(sortDirection))}
      >
        <ChevronUpIcon className={clsx('w-3 h-3', sortDirection === 'asc' && 'text-gray-500')} />
        <ChevronDownIcon
          className={clsx('w-3 h-3', sortDirection === 'desc' && 'text-gray-500')}
          style={{ marginTop: '-6px' }}
        />
      </div>
    </th>
  );
};
