import { AuthLayout } from '../views/auth/auth-layout';
import { SetupPasswordForm } from '../views/auth/setup-password';

export const SetupPasswordRoute = () => {
  return (
    <AuthLayout>
      <SetupPasswordForm />
    </AuthLayout>
  );
};
