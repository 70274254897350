import { useState } from 'react';

import { post } from './use-query';

const getContentType = (fileExtension: string): string => {
  if (fileExtension === 'xls') {
    return 'application/vnd.ms-excel';
  }

  if (fileExtension === 'xlsx') {
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }

  return 'application/octet-stream';
};

export const useUploadSubmissionFile = () => {
  const [isLoading, setIsLoading] = useState(false);

  const execute = async (file: File, title: string) => {
    try {
      setIsLoading(true);

      const filePaths = file.name.split('.');
      const fileExtension = filePaths[filePaths.length - 1];

      const {
        data: { submissionId, url: presignedUrl },
      } = await post(`${process.env.REACT_APP_API_URL}/submissions`, {
        filename: file.name,
        title,
      });

      await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': getContentType(fileExtension) },
      });

      return { submissionId };
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    execute,
    isLoading,
  };
};
