import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';

import type { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';

export interface PopperCoreProps {
  children: ReactNode;
  offset?: [number, number];
  placement?: Placement;
  popperElement: HTMLElement | null;
  referenceElement: HTMLElement | null;
  setPopperElement: Dispatch<SetStateAction<HTMLElement | null>>;
}

export const PopperCore: FC<PopperCoreProps> = ({
  children,
  offset = [0, 0],
  placement,
  popperElement,
  referenceElement,
  setPopperElement,
}) => {
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'offset', options: { offset } }],
    placement,
  });

  return (
    <div className="z-10" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      {children}
    </div>
  );
};
